import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Home
  //{ path: 'home-one', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: "Homepage" } }, 
   { path: '', loadChildren: () => import('./components/pages/home-six/home-six.module').then(m => m.HomeSixModule), data: { breadcrumb: "Homepage" } }, 
  { path: 'home-v2', loadChildren: () => import('./components/pages/home-two/home-two.module').then(m => m.HomeTwoModule), data: { breadcrumb: "Homepage" } }, 
  { path: 'home-v3', loadChildren: () => import('./components/pages/home-three/home-three.module').then(m => m.HomeThreeModule), data: { breadcrumb: "Homepage" } }, 
  { path: 'home-v4', loadChildren: () => import('./components/pages/home-four/home-four.module').then(m => m.HomeFourModule), data: { breadcrumb: "Homepage" } }, 
  { path: 'home-v5', loadChildren: () => import('./components/pages/home-five/home-five.module').then(m => m.HomeFiveModule), data: { breadcrumb: "Homepage" } }, 
  { path: 'home-v6', loadChildren: () => import('./components/pages/home-six/home-six.module').then(m => m.HomeSixModule), data: { breadcrumb: "Homepage" } }, 
  // Services
  { path: 'services', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: "Services" } },
  { path: 'service-details/:id', loadChildren: () => import('./components/pages/service-details/service-details.module').then(m => m.ServiceDetailsModule), data: { breadcrumb: "Service Details" } },
  // Pricing
  { path: 'pricing', loadChildren: () => import('./components/pages/pricing/pricing.module').then(m => m.PricingModule), data: { breadcrumb: "Pricing" } },
  // Pages
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: "About Us" } },
  { path: 'certificates', loadChildren: () => import('./components/pages/certificates/about.module').then(m => m.AboutModule), data: { breadcrumb: "Certificates" } },
  { path: 'history', loadChildren: () => import('./components/pages/history/about.module').then(m => m.AboutModule), data: { breadcrumb: "Evolution History" } },

  { path: 'digiprint', loadChildren: () => import('./components/pages/digiprint/about.module').then(m => m.AboutModule), data: { breadcrumb: "Digi Print" } },
  { path: 'imi', loadChildren: () => import('./components/pages/imi/about.module').then(m => m.AboutModule), data: { breadcrumb: "IMI" } },
  { path: 'integratedadvertising', loadChildren: () => import('./components/pages/integratedadvertising/about.module').then(m => m.AboutModule), data: { breadcrumb: "Integrated Advertising" } },
  { path: 'integratedmarketing', loadChildren: () => import('./components/pages/integratedmarketing/about.module').then(m => m.AboutModule), data: { breadcrumb: "Integrated Marketing" } },
  { path: 'landmark', loadChildren: () => import('./components/pages/landmark/about.module').then(m => m.AboutModule), data: { breadcrumb: "Land Mark" } },
  { path: 'royal', loadChildren: () => import('./components/pages/royal/about.module').then(m => m.AboutModule), data: { breadcrumb: "Royal Media" } },
  { path: 'transmaster', loadChildren: () => import('./components/pages/transmaster/about.module').then(m => m.AboutModule), data: { breadcrumb: "Trans Masters" } },
 
  { path: 'service1', loadChildren: () => import('./components/pages/Service1/about.module').then(m => m.AboutModule), data: { breadcrumb: "DigiPrint International" } },
  { path: 'service20', loadChildren: () => import('./components/pages/service20/about.module').then(m => m.AboutModule), data: { breadcrumb: "Integrated Media Group" } },
  { path: 'service2', loadChildren: () => import('./components/pages/service2/about.module').then(m => m.AboutModule), data: { breadcrumb: "Salamtak" } },
  { path: 'service3', loadChildren: () => import('./components/pages/service3/about.module').then(m => m.AboutModule), data: { breadcrumb: "Packaging & Graphic" } },
  { path: 'service4', loadChildren: () => import('./components/pages/service4/about.module').then(m => m.AboutModule), data: { breadcrumb: "Premium items" } },
  { path: 'service5', loadChildren: () => import('./components/pages/service5/about.module').then(m => m.AboutModule), data: { breadcrumb: "Events organizing" } },
  { path: 'service6', loadChildren: () => import('./components/pages/service6/about.module').then(m => m.AboutModule), data: { breadcrumb: "Exhibitions production" } },
  { path: 'service7', loadChildren: () => import('./components/pages/service7/about.module').then(m => m.AboutModule), data: { breadcrumb: "Printing" } },
  { path: 'service8', loadChildren: () => import('./components/pages/service8/about.module').then(m => m.AboutModule), data: { breadcrumb: "Manufacturing" } },
  { path: 'service9', loadChildren: () => import('./components/pages/service9/about.module').then(m => m.AboutModule), data: { breadcrumb: "Manipulation" } },
  { path: 'service10', loadChildren: () => import('./components/pages/service10/about.module').then(m => m.AboutModule), data: { breadcrumb: "IMI Manipulation" } },
  { path: 'service11', loadChildren: () => import('./components/pages/service11/about.module').then(m => m.AboutModule), data: { breadcrumb: "Production lines outsourcing" } },
  { path: 'service12', loadChildren: () => import('./components/pages/service12/about.module').then(m => m.AboutModule), data: { breadcrumb: "Direct Marketing Services" } },
  { path: 'service18', loadChildren: () => import('./components/pages/service18/about.module').then(m => m.AboutModule), data: { breadcrumb: "Direct Marketing Services" } },
  { path: 'service13', loadChildren: () => import('./components/pages/service13/about.module').then(m => m.AboutModule), data: { breadcrumb: "Outdoor locations" } },
  { path: 'service14', loadChildren: () => import('./components/pages/service14/about.module').then(m => m.AboutModule), data: { breadcrumb: "Transportations" } },
  { path: 'service15', loadChildren: () => import('./components/pages/service15/about.module').then(m => m.AboutModule), data: { breadcrumb: "Manufacturing" } },
  { path: 'service16', loadChildren: () => import('./components/pages/service16/about.module').then(m => m.AboutModule), data: { breadcrumb: "ROYAL MEDIA" } },
  { path: 'team', loadChildren: () => import('./components/pages/team/team.module').then(m => m.TeamModule), data: { breadcrumb: "Team" } },
  { path: 'coming-soon', loadChildren: () => import('./components/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule), data: { breadcrumb: "Coming Soon" } },
  { path: 'error', loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule), data: { breadcrumb: 'Error 404' } },
  // Blog
  { path: 'blog/search/:query', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog-grid', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog-standard', loadChildren: () => import('./components/pages/blog-list/blog-list.module').then(m => m.BlogListModule), data: { breadcrumb: "Blog Standard" } },
  { path: 'blog-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: "Blog Details" } },
  // Contact
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: "Contact Us" } },
   
  { path: '**', loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule), data: { breadcrumb: 'Error 404' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
